<template>
  <div :class="$style.authentication">
    <div :class="[$style.container, $style.authContent]">
      <div :class="$style.logo">
        <router-link to="/" tag="a" :class="$style.logo">
          <svg
            width="28"
            height="35"
            viewBox="0 0 28 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M27.1553 0H15.5173V35H27.1553V0Z" fill="#0D46D6" />
            <path d="M11.638 0H0V19.4444H11.638V0Z" fill="#0D46D6" />
            <path d="M11.638 23.3333H0V35H11.638V23.3333Z" fill="#0D46D6" />
          </svg>
        </router-link>
      </div>
      <h4 style="margin-top: 32px">{{ authTitle }}</h4>
      <div :class="$style.loginOptions">
        <button @click="loginWith('google')" :class="$style.loginOption">
          <div :class="$style.loginElements">
            <div :class="$style.icon">
              <svg
                viewBox="0 0 18 18"
                role="presentation"
                aria-hidden="true"
                focusable="false"
                style="height: 20px; width: 20px; display: block;"
              >
                <g fill="none" fill-rule="evenodd">
                  <path
                    d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                    fill="#EA4335"
                  ></path>
                  <path
                    d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                    fill="#4285F4"
                  ></path>
                  <path
                    d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                    fill="#FBBC05"
                  ></path>
                  <path
                    d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                    fill="#34A853"
                  ></path>
                  <path d="M0 0h18v18H0V0z"></path>
                </g>
              </svg>
            </div>
            <div :class="$style.text">{{ authTitle }} with Google</div>
            <div :class="$style.icon"></div>
          </div>
        </button>
        <button @click="loginWith('facebook')" :class="$style.loginOption">
          <div :class="$style.loginElements">
            <div :class="$style.icon">
              <svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                role="presentation"
                aria-hidden="true"
                focusable="false"
                style="height: 20px; width: 20px; display: block; fill: currentcolor;"
              >
                <defs>
                  <path id="a" d="M.001 0H24v23.854H.001z"></path>
                </defs>
                <g fill="none" fill-rule="evenodd">
                  <mask id="b" fill="#fff"><use xlink:href="#a"></use></mask>
                  <path
                    d="M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12c0 5.99 4.388 10.954 10.125 11.854V15.47H7.078V12h3.047V9.356c0-3.007 1.792-4.668 4.533-4.668 1.312 0 2.686.234 2.686.234v2.953H15.83c-1.491 0-1.956.925-1.956 1.874V12h3.328l-.532 3.469h-2.796v8.385C19.612 22.954 24 17.99 24 12"
                    fill="#1877F2"
                    mask="url(#b)"
                  ></path>
                  <path
                    d="M16.671 15.469L17.203 12h-3.328V9.749c0-.949.465-1.874 1.956-1.874h1.513V4.922s-1.374-.234-2.686-.234c-2.741 0-4.533 1.66-4.533 4.668V12H7.078v3.469h3.047v8.385a12.09 12.09 0 003.75 0V15.47h2.796"
                    fill="#FFF"
                  ></path>
                </g>
              </svg>
            </div>
            <div :class="$style.text">{{ authTitle }} with Facebook</div>
            <div :class="$style.icon"></div>
          </div>
        </button>
      </div>
      <div :class="$style.terms">
        <p>
          By continuing, you agree to our
          <a href="https://www.aurizor.com/terms" target="_blank"
            >Terms of Service</a
          >
          and
          <a href="https://www.aurizor.com/privacy" target="_blank"
            >Privacy Policy</a
          >.
        </p>
      </div>
      <div :class="$style.loginSignup">
        <p v-if="$route.name === 'Signup'">
          Already have an account? <router-link to="/login">Log in</router-link>
        </p>
        <p v-else>
          Don't have an account? <router-link to="/signup">Sign up</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Authentication",
  computed: {
    authTitle() {
      return this.$route.name === "Login" ? "Log in" : "Sign up";
    },
  },
  methods: {
    loginWith(type) {
      window.location = `${process.env.VUE_APP_API_URL}/auth/${type}`;
    },
  },
};
</script>

<style lang="scss" module>
.authentication {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999999;
  background: #fff;

  .authContent {
    text-align: center;
  }

  .logo {
    margin-top: 80px;
    svg {
      height: 60px;
      width: auto;
    }
  }

  .login-options {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .login-option {
      width: 400px;
      padding: 16px 12px;
      background: transparent;
      border: 2px solid var(--border);
      margin-bottom: 16px;
      &:hover,
      &:focus {
        border-color: var(--aurizor-blue);
      }
      .login-elements {
        display: flex;
        align-items: center;
        .icon {
          flex: 0 1 0 !important;
        }
        .text {
          flex: 1 1 0 !important;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }

  .terms {
    width: 400px;
    margin: 4px auto 0;
    font-size: 1.4rem;
    a {
      color: var(--black);
      font-weight: 600;
    }
  }

  .login-signup {
    margin-top: 48px;
    font-size: 1.6rem;

    a {
      color: var(--black);
      font-weight: 600;
    }
  }
}
</style>
